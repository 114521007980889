<div class="app-account-form">
  <p class="app-account-form__informative">
    Informações adicionais e de senha de acesso
  </p>

  @if (extraFields.length > 0) {
  <div class="app-account-form__extra-fields">
    <formly-form
      [fields]="extraFields"
      [form]="formDynamic"
      [model]="formDynamicModel"
    ></formly-form>

    <span class="app-account-form__extra-fields__divider"></span>
  </div>
  }

  <div class="create-account-form-row">
    <app-input
      [inputType]="!showPassword() ? 'password' : 'text'"
      [control]="form.controls.password"
      label="Senha"
      placeholder="Digite sua senha"
      (onFocus)="showPasswordRulesTooltip('password', $event)"
      (onBlur)="hidePasswordRulesTooltip()"
    >
      <ng-container append>
        <app-icon
          [name]="!showPassword() ? 'eye-off-outline' : 'eye-outline'"
          size="20px"
          color="#9ca3af"
          class="cursor-pointer"
          (click)="toggleInputPassword()"
        />
      </ng-container>
    </app-input>

    <app-input
      [inputType]="!showConfirmPassword() ? 'password' : 'text'"
      [control]="form.controls.confirmPassword"
      label="Confirmar Senha"
      placeholder="Digite sua senha"
      (onInput)="validateConfirmPasswordRules()"
      (onFocus)="showPasswordRulesTooltip('confirmPassword', $event)"
      (onBlur)="hidePasswordRulesTooltip()"
    >
      <ng-container append>
        <app-icon
          [name]="!showConfirmPassword() ? 'eye-off-outline' : 'eye-outline'"
          size="20px"
          color="#9ca3af"
          class="cursor-pointer"
          (click)="toggleInputPassword(true)"
        />
      </ng-container>
    </app-input>
  </div>

  <div class="create-account-form-row">
    <app-checkbox
      [control]="form.controls.termos"
      class="font-normal text-sm leading-4 text-gray-500"
      color="#06929F"
    >
      Li e aceito os
      <a
        href="https://assets-publicos.s3.amazonaws.com/Termos+de+Uso.pdf"
        target="_blank"
      >
        <b> termos de uso</b>
      </a>
    </app-checkbox>
  </div>

  @if(!hideRecaptcha){
  <app-recaptcha [recaptchaFormControl]="form.controls.recaptchaReactive" />
  }

  <div class="mt-1">
    @if (form.controls['termos'].errors && !form.controls['termos'].pristine) {
    <div>É necessário concordar com os termos de uso para prosseguir.</div>
    }
  </div>

  <div #passwordRulesTooltipRef class="box-password-rules">
    <div class="box-password-rules__title">
      Prezamos pela segurança dos seus dados e para isso, sua senha deve atender
      aos seguintes requisitos:
    </div>
    <div class="box-password-rules__content">
      <div class="box-password-rules__content__rules">
        @for(rule of rules; track rule.label) {
        <div
          class="box-password-rules__content__rules__rule"
          [class.box-password-rules__content__rules__rule--valid]="rule.valid"
        >
          <app-icon
            [name]="rule.valid ? 'check-solid' : 'exclamation-circle-outline'"
            size="20px"
            [color]="rule.valid ? '#22C55E' : '#EF4444'"
          />
          {{ rule.label }}
        </div>
        }
      </div>
    </div>
  </div>
</div>
