import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ButtonComponent } from '@aprova-digital/design-system';
import { switchMap, tap } from 'rxjs';

import { City } from '../../../domain/city/entities/city.entity';

import { StepperComponent } from '../../components/stepper/stepper.component';
import { IconComponent } from '../../components/icon/icon.component';

import { Types } from '../../../domain/types.domain';
import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { UserRepositoryInstruction } from '../../../builder/instructions/repositories/user.repository.instructions';
import { VerifyCreateAccountTokenServiceInstruction } from '../../../builder/instructions/services/verify-create-account-token.service.instruction';
import { CreateAccountServiceInstruction } from '../../../builder/instructions/services/create-account.service.instruction';
import { InitialCreateAccountInput } from '../../../domain/user/dtos/initial-create-account.input';

import { PersonalFormComponent } from './components/personal-form/personal-form.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AccountFormComponent } from './components/account-form/account-form.component';
import { ButtonComponent as OldButtonComponent } from '../../components/button/button.component';

import { AppHandler } from '../../app.handler';
import { AccountFormHandler } from './account-form.handler';

@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [
    PersonalFormComponent,
    AddressFormComponent,
    AccountFormComponent,
    OldButtonComponent,
    ButtonComponent,
    StepperComponent,
    IconComponent,
  ],
  providers: [
    AccountFormHandler,
    new AuthRepositoryInstruction(),
    new UserRepositoryInstruction(),
    new VerifyCreateAccountTokenServiceInstruction(),
    new CreateAccountServiceInstruction(),
  ],
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss',
})
export class CreateAccountComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  private readonly _authRepository = inject(Types.AuthRepository);
  private readonly _verifyCreateAccountTokenService = inject(
    Types.VerifyCreateAccountTokenService
  );
  private readonly _createAccountService = inject(Types.CreateAccountService);

  private readonly _appHandler = inject(AppHandler);
  private readonly _accountFormHandler = inject(AccountFormHandler);

  public saving = signal(false);

  public steps = [
    { label: 'Dados pessoais', valid: false },
    { label: 'Endereço', valid: false },
  ];

  public cityEntity: City | undefined;
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];
  public cityLoginUrl: string = '';
  public extraFields: FormlyFieldConfig[] = [];

  public hideRecaptcha: boolean = false;
  public hidePasswordFields: boolean = false;
  public initialAccountData?: InitialCreateAccountInput;
  public tokenId: string = '';
  public recaptchaReactive: string = '';
  public errorMessage = signal('');
  public hasError: boolean = false;

  public showWelcomeMessage = signal(true);
  public preRegisteredAccount = signal(false);

  public blockedFields: string[] = this.routeState['blockedFields'] ?? [];
  public isBusinessAccount = signal(false);
  public sendConfirmationEmail = signal(true);
  public accountCreated = signal(false);

  public error?: string = undefined;
  private verification?: {
    code: string;
    strategy: string;
    provider?: string;
  };

  get currentStep(): number {
    return this._accountFormHandler.getStep();
  }

  get routeState() {
    return this._router.lastSuccessfulNavigation?.extras?.state ?? {};
  }

  get isCurrentStepValid(): boolean {
    return this.steps[this.currentStep].valid;
  }

  ngOnInit(): void {
    const createAccountData = localStorage.getItem('createAccountData');
    if (createAccountData) {
      localStorage.removeItem('createAccountData');
      const createAccountDataParsed = JSON.parse(createAccountData);
      this._accountFormHandler.setProfileInfo(createAccountDataParsed);
      this._accountFormHandler.setAddressInfo(createAccountDataParsed);
    }

    this._appHandler.setBackButtonHome(false);

    this._route.data
      .pipe(
        tap(async ({ city }) => {
          if (!city.active) {
            this._appHandler.setLoading(false);
            this._appHandler.hideBackButton(true);
            this._appHandler.setError(true, {
              iconName: 'exclamation-outline',
              iconSize: '48px',
              message: 'Indisponível',
              description:
                'Consulte a organização para maiores esclarecimentos',
              options: {
                iconBackgroundColor: '#fff',
                messageClasses: 'font-bold',
                messageHorizontalAlign: 'center',
              },
            });
            return;
          }

          const config = await this._authRepository.getConfigByCityId(
            city.index
          );
          this.hideRecaptcha = config?.hideRecaptcha ?? false;
          this.hidePasswordFields = config?.hidePasswordFields ?? false;

          if (!this.hidePasswordFields && this.steps.length < 3) {
            this.steps.push({ label: 'Dados acesso', valid: false });
          }

          this.cityEntity = city;
          this.cityIdentity = city.identidade;
          this.cityConnection = city.connection;
          this.cityLoginUrl = city.loginUrl;
          this.extraFields = this.mountExtraFields(
            city.users?.extraFields ?? []
          );
          if (this.routeState) {
            if (this.routeState['initialAccountData']) {
              this.initialAccountData = this.routeState['initialAccountData'];
            }

            if (this.routeState['blockedFields']) {
              this.blockedFields = this.routeState['blockedFields'];
            }

            if (this.routeState['verification']) {
              this.verification = this.routeState['verification'];
            }
          }
        }),
        switchMap(() => this._route.queryParams)
      )
      .subscribe({
        next: async (params) => {
          if (params?.['tokenId']) {
            this.tokenId = params['tokenId'];
          }

          const profileForm = this._accountFormHandler.getProfileInfo() ?? {};
          if (params?.['email']) {
            profileForm.email = params['email'];
          }

          if (params?.['name']) {
            const splittedName = params['name'].split(' ');
            profileForm.nome = splittedName.shift() ?? '';
            profileForm.sobrenome = splittedName.shift() ?? '';
          }

          if (params?.['cpf']) {
            profileForm.cpf = params['cpf'];
          }

          if (params?.['skipAccountConfirmation'] === 'true') {
            this.sendConfirmationEmail.set(false);
          }

          if (params?.['showWelcomeMessage'] === 'false') {
            this.showWelcomeMessage.set(false);
          }

          if (params?.['preRegisteredAccount'] === 'true') {
            this.preRegisteredAccount.set(true);
            this.sendConfirmationEmail.set(false);
          }

          if (params?.['tokenId']) {
            await this._verifyCreateAccountTokenService
              .handle({
                tokenId: this.tokenId,
              })
              .then((response) => {
                this._accountFormHandler.setProfileInfo({
                  type: response.type ?? 'personal',
                  nome: response.firstName ?? '',
                  sobrenome: response.lastName ?? '',
                  email: response.email ?? '',
                  cpf: response.cpf ?? '',
                  telefone: response.phoneNumber ?? '',
                });
              })
              .catch((err) => {
                if (err.error.code === 'inviteTokenInvalidOrExpired') {
                  this._appHandler.setError(true, {
                    message: 'Ops... Este link expirou.',
                    description:
                      'Para continuar, solicite um novo link com a pessoa ou organização responsável.',
                  });
                }

                this._appHandler.setError(true, {
                  message: 'A operação não pôde ser concluída.',
                  description:
                    'Por favor, revise seus dados antes de fazer uma nova tentativa. Se o problema persistir, entre em contato com nossa equipe de suporte.',
                  actionText: 'Tentar novamente',
                  action: () => {
                    this._appHandler.setLoading(true);
                    this._appHandler.clearError();
                  },
                });

                this._appHandler.setLoading(false);
              });
          } else {
            this._accountFormHandler.setProfileInfo(profileForm);
            this._appHandler.setLoading(false);
          }
        },
      });

    if (this.initialAccountData) {
      this._accountFormHandler.setProfileInfo({
        type: this.initialAccountData.type ?? 'personal',
        nome: this.initialAccountData.userMetadata?.firstName ?? '',
        sobrenome: this.initialAccountData.userMetadata?.lastName ?? '',
        email: this.initialAccountData.email ?? '',
        cpf: this.initialAccountData.userMetadata?.cpf ?? '',
        telefone: this.initialAccountData.userMetadata?.phoneNumber ?? '',
      });
      this._accountFormHandler.setAddressInfo({
        cep: this.initialAccountData.userMetadata?.cep ?? '',
        rua: this.initialAccountData.userMetadata?.rua ?? '',
        numero: this.initialAccountData.userMetadata?.numero ?? '',
        complemento: this.initialAccountData.userMetadata?.complemento ?? '',
        bairro: this.initialAccountData.userMetadata?.bairro ?? '',
        cidade: this.initialAccountData.userMetadata?.cidade ?? '',
        estado: this.initialAccountData.userMetadata?.estado ?? '',
      });
    }
  }

  redirectToLogin(): void {
    if (this.cityLoginUrl) {
      window.location.href = this.cityLoginUrl;
      return;
    }

    this._router.navigate([`/${this.cityIdentity}`]);
  }

  startAccountCreation(): void {
    this.showWelcomeMessage.set(false);
    this._accountFormHandler.setStep(0);
  }

  goToStep(stepNumber: number): void {
    if (stepNumber < 0) {
      if (this.preRegisteredAccount()) {
        this.redirectToLogin();
        return;
      }

      this.showWelcomeMessage.set(true);
      return;
    }

    if (stepNumber > this.steps.length - 1) {
      this.createUser();
      return;
    }

    this._accountFormHandler.setStep(Math.max(stepNumber, 0));
  }

  setStepValidation(stepNumber: number, valid: boolean): void {
    if (this.steps[stepNumber]) {
      this.steps[stepNumber].valid = valid;
    }
  }

  onChangeRecaptcha(recaptchaReactive: string): void {
    this.recaptchaReactive = recaptchaReactive;
  }

  createUser(): void {
    this._appHandler.setLoading(true, {
      title: 'Criando conta',
      description: 'Aguarde enquanto criamos sua conta',
    });

    this._createAccountService
      .handle({
        ...this._accountFormHandler.getCompleteInfo(),
        verifyMail: this.sendConfirmationEmail(),
        cityControl: {
          city: this.cityIdentity.slice(0, this.cityIdentity.length - 2),
          state: this.cityIdentity.slice(this.cityIdentity.length - 2),
        },
        connection: this.cityConnection,
        tokenId: this.tokenId,
        recaptchaReactive: this.recaptchaReactive,
        loginAfterInserted: !this.sendConfirmationEmail(),
        verification: this.verification,
      })
      .then((response) => {
        this.accountCreated.set(true);
        this._appHandler.setLoading(false);

        if (!this.sendConfirmationEmail() && response.redirectUri) {
          setTimeout(() => {
            window.location.href = `${response.redirectUri}?code=${response.code}`;
          }, 5000);
        }
      })
      .catch((err) => {
        // salvar no localstorage para caso de retentativa de criar conta
        localStorage.setItem(
          'createAccountData',
          JSON.stringify(this._accountFormHandler.getCompleteInfo())
        );

        this._appHandler.setError(true, {
          message: 'Não foi possível criar a conta',
          description: err.error?.message ?? err.message,
          actionText: 'Tentar novamente',
          action: () => {
            this._appHandler.clearError();
            this._router.navigate([`${this.cityIdentity}/create-account`], {
              queryParams: {
                skipAccountConfirmation: !this.sendConfirmationEmail(),
                showWelcomeMessage: false,
                preRegisteredAccount: this.preRegisteredAccount(),
              },
            });
          },
        });

        this.accountCreated.set(false);
        this._appHandler.setLoading(false);
      });
  }

  // Função para montar os campos extras vindos do backend, separando em 2 colunas
  private mountExtraFields(
    extraFields: FormlyFieldConfig[]
  ): FormlyFieldConfig[] {
    const isOdd = extraFields.length % 2 !== 0;
    const newExtraFields: FormlyFieldConfig[] = [];

    for (let i = 0; i < extraFields.length; i++) {
      if (!extraFields[i]['className']) {
        extraFields[i]['className'] = 'w-full';
      } else if (!extraFields[i]['className']?.includes('w-full')) {
        extraFields[i]['className'] += ' w-full';
      }

      if (i > 0 && i % 2 !== 0) {
        newExtraFields.push({
          fieldGroupClassName: 'flex justify-between gap-x-6 w-full',
          fieldGroup: [extraFields[i - 1], extraFields[i]],
        });
      }

      if (isOdd && i === extraFields.length - 1) {
        newExtraFields.push({
          fieldGroupClassName: 'flex justify-between gap-x-6 w-full',
          fieldGroup: [
            extraFields[i],
            {
              type: 'input',
              key: 'empty',
              className: 'w-full opacity-0',
              props: {
                required: false,
              },
            },
          ],
        });
      }
    }

    return newExtraFields;
  }
}
