import {
  Component,
  ElementRef,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, debounceTime, map, switchMap, tap } from 'rxjs';
import { ButtonComponent } from '@aprova-digital/design-system';

import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { VerifyChangePasswordTokenServiceInstruction } from '../../../builder/instructions/services/verify-change-password-token.service.instruction';
import { VerifyUserPasswordServiceInstruction } from '../../../builder/instructions/services/verify-user-password.service.instruction';
import { ResetPasswordServiceInstruction } from '../../../builder/instructions/services/reset-password.service.instruction';
import { Types } from '../../../domain/types.domain';

import {
  Rule,
  getPasswordRules,
  getConfirmPasswordRules,
} from '../../utils/password.rules';
import { AppHandler } from '../../app.handler';

import { InputComponent } from '../../components/input/input.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { RecaptchaComponent } from '../../components/recaptcha/recaptcha.component';
import { IconComponent } from '../../components/icon/icon.component';
import { BoxErrorComponent } from '../../components/box-error/box-error.component';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    LoadingSpinnerComponent,
    RecaptchaComponent,
    InputComponent,
    IconComponent,
    ButtonComponent,
    BoxErrorComponent,
  ],
  providers: [
    new AuthRepositoryInstruction(),
    new ResetPasswordServiceInstruction(),
    new VerifyChangePasswordTokenServiceInstruction(),
    new VerifyUserPasswordServiceInstruction(),
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  @ViewChild('passwordRulesTooltipRef', { static: true })
  public passwordRulesTooltipRef: ElementRef<HTMLDivElement> | undefined;

  private readonly _verifyChangePasswordTokenService = inject(
    Types.VerifyChangePasswordTokenService
  );
  private readonly _verifyUserPasswordService = inject(
    Types.VerifyUserPasswordService
  );
  private readonly _resetPasswordService = inject(Types.ResetPasswordService);

  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  private readonly _appHandler = inject(AppHandler);
  private readonly _authRepository = inject(Types.AuthRepository);

  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];
  public hideRecaptcha: boolean = false;

  public type: string = 'change';
  public email: string = '';
  public token: string = '';

  public rules: Rule[] = [];
  private passwordRules: Rule[] = getPasswordRules();
  private confirmPasswordRules: Rule[] = getConfirmPasswordRules();

  public showPassword = signal(false);
  public showConfirmPassword = signal(false);
  public verifyingLastPassword = signal(false);
  public saving = signal(false);
  public passwordChanged = signal(false);
  public redirectTimeout: any;

  public form = new FormGroup({
    password: new FormControl('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    confirmPassword: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    recaptchaReactive: new FormControl(''),
  });

  get isPasswordValid() {
    return this.passwordRules.every((rule) => rule.valid);
  }

  get isConfirmPasswordValid() {
    return this.confirmPasswordRules.every((rule) => rule.valid);
  }

  protected readonly data$ = combineLatest({
    params: this._route.queryParams.pipe(
      tap(({ email, token, type }) => {
        if (!email || !token) {
          this.redirectToLogin();
          return;
        }

        this._verifyChangePasswordTokenService
          .handle({
            connection: this.cityConnection,
            email,
            token,
          })
          .then(() => {
            this.email = email;
            this.token = token;
            this.type = type || 'change';
          })
          .catch(() => {
            this.redirectToLogin();
          });
      })
    ),
    city: this._route.data.pipe(
      map(({ city }) => ({
        cityIdentity: city.identidade,
        cityConnection: city.connection,
      })),
      tap((data) => {
        this.cityIdentity = data.cityIdentity;
        this.cityConnection = data.cityConnection;
      })
    ),
    config: this._route.data.pipe(
      switchMap(({ city }) =>
        this._authRepository.getConfigByCityId(city.index)
      )
    ),
  });

  ngOnInit(): void {
    this._appHandler.setBackButtonHome(false);
    this._appHandler.setLoading(false);

    this.passwordRules.push({
      key: 'lastPassword',
      label: 'A senha não pode ser igual à senha anterior.',
      valid: false,
      regex: '',
    });

    this.form.controls.password.valueChanges
      .pipe(
        tap(() => {
          this.validatePasswordRules();
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        this.verifyLastPassword();
      });
  }

  redirectToLogin(): void {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }

    this._appHandler.setLoading(true);
    this._router.navigate([`/${this.cityIdentity}`]);
  }

  onSubmit(): void {
    this._appHandler.setError(false);

    if (this.form.valid) {
      this.saving.set(true);

      this._resetPasswordService
        .handle({
          city: this.cityIdentity,
          connection: this.cityConnection,
          email: this.email,
          token: this.token,
          password: this.form.controls.password.value,
          recaptchaReactive: this.form.controls.recaptchaReactive.value,
        })
        .then(() => {
          this.saving.set(false);
          this.passwordChanged.set(true);

          this.redirectTimeout = setTimeout(() => {
            this.redirectToLogin();
          }, 5000);
        })
        .catch(() => {
          this.saving.set(false);
          this._appHandler.setError(true, {
            message: 'A operação não pôde ser concluída.',
            description:
              'Por favor, revise seus dados antes de fazer uma nova tentativa. Se o problema persistir, entre em contato com nossa equipe de suporte.',
            actionText: 'Tentar novamente',
            action: () => {
              this._appHandler.clearError();
            },
          });
        });
    }
  }

  toggleInputPassword(): void {
    this.showPassword.update((val) => !val);
  }

  toggleInputConfirmPassword(): void {
    this.showConfirmPassword.update((val) => !val);
  }

  showPasswordRulesTooltip(
    input: 'password' | 'confirmPassword',
    event: FocusEvent
  ): void {
    if (this.passwordRulesTooltipRef) {
      if (input === 'confirmPassword') {
        this.rules = this.confirmPasswordRules;
        this.validateConfirmPasswordRules();
      } else {
        this.rules = this.passwordRules;
        this.validatePasswordRules();
      }

      const target = event.target as HTMLInputElement;
      const isPortrait = window.innerHeight > window.innerWidth;
      const isPortraitClassName = 'box-password-rules--portrait';

      if (isPortrait) {
        const top =
          (target.parentElement?.offsetTop ?? 0) +
          (target.parentElement?.clientHeight ?? 0);
        const left = target.parentElement?.offsetLeft ?? 0;
        this.passwordRulesTooltipRef.nativeElement.classList.add(
          isPortraitClassName
        );
        this.passwordRulesTooltipRef.nativeElement.style.top = `${top + 20}px`;
        this.passwordRulesTooltipRef.nativeElement.style.display = 'block';
        this.passwordRulesTooltipRef.nativeElement.style.left = `${left}px`;
        return;
      }

      const top = target.parentElement?.offsetTop ?? 0;
      const left = target.parentElement?.offsetLeft ?? 0;
      this.passwordRulesTooltipRef.nativeElement.classList.remove(
        isPortraitClassName
      );
      this.passwordRulesTooltipRef.nativeElement.style.top = `${top - 20}px`;
      this.passwordRulesTooltipRef.nativeElement.style.left = `${left - 380}px`;
      this.passwordRulesTooltipRef.nativeElement.style.display = 'block';
    }
  }

  hidePasswordRulesTooltip(): void {
    if (this.passwordRulesTooltipRef) {
      this.passwordRulesTooltipRef.nativeElement.style.display = 'none';
    }
  }

  validatePasswordRules(): void {
    const password = this.form.controls['password'].value;
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.form.controls.password.setErrors(null);

    this.passwordRules.forEach((rule) => {
      if (rule.regex) {
        rule.valid = new RegExp(rule.regex, 'g').test(password);

        if (!rule.valid) {
          this.form.controls['password'].setErrors({
            [rule.key]: true,
          });
        }
      }
    });

    if (confirmPassword) {
      this.validateConfirmPasswordRules();
    }
  }

  validateConfirmPasswordRules(): void {
    const password = this.form.controls['password'].value;
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.form.controls.confirmPassword.setErrors(null);

    this.confirmPasswordRules.forEach((rule) => {
      rule.valid = Boolean(password === confirmPassword);

      if (!rule.valid) {
        this.form.controls['confirmPassword'].setErrors({
          [rule.key]: true,
        });
      }
    });
  }

  private verifyLastPassword(): void {
    const password = this.form.controls['password'].value;
    this.passwordRules[this.passwordRules.length - 1].valid = false;

    if (!password || (password && password.length < 8)) {
      return;
    }

    this.verifyingLastPassword.set(true);
    this._verifyUserPasswordService
      .handle({
        connection: this.cityConnection,
        email: this.email,
        password,
      })
      .then(
        () => {
          this.passwordRules[this.passwordRules.length - 1].valid = false;
          this.form.controls['password'].setErrors({
            lastPassword: true,
          });
          this.verifyingLastPassword.set(false);
        },
        () => {
          this.passwordRules[this.passwordRules.length - 1].valid = true;
          this.verifyingLastPassword.set(false);
        }
      );
  }
}
