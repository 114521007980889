import { Injectable, inject } from '@angular/core';
import LacunaWebPKI, {
  CertificateModel,
  Promise as LacunaPromise,
} from 'web-pki';

import { Types } from '../../domain/types.domain';

@Injectable()
export class WebPkiAdapter {
  private readonly _settings = inject(Types.Settings);

  private instance!: LacunaWebPKI;
  public certificateList: CertificateModel[] = [];

  async isInitialized(): Promise<{ sucess: boolean; error?: string }> {
    return new Promise((resolve) => {
      if (this.instance) {
        return resolve({ sucess: true });
      }

      const pki = new LacunaWebPKI(this._settings.webpki.apiKey);
      pki.init({
        ready: () => {
          this.instance = pki;
          this.listCertificates();
          resolve({ sucess: true });
        },
        notInstalled: () => {
          resolve({ sucess: false, error: 'not_installed' });
        },
        defaultFail: (error) => {
          resolve({ sucess: false, error: error.code });
        },
      });
    });
  }

  public listCertificates(): Promise<CertificateModel[]> {
    if (!this.instance) {
      return Promise.resolve([]);
    }
    return this.toNativePromise(this.instance.listCertificates());
  }

  public async getICPCertificates(): Promise<CertificateModel[]> {
    if (!this.instance) {
      return Promise.resolve([]);
    }

    const certificates = await this.toNativePromise(
      this.instance.listCertificates()
    );

    return certificates.filter((cert) => {
      const rootNameRegex = /\,O=([^)]+)\,/;
      const matchResults = rootNameRegex.exec(cert.issuerDN);
      return matchResults?.[1]?.toLowerCase() === 'icp-brasil';
    });
  }

  public readCertificate(thumbprint: {
    thumbprint: string;
  }): Promise<string | null> {
    if (!this.instance) {
      return Promise.resolve(null);
    }
    return this.toNativePromise(this.instance.readCertificate(thumbprint));
  }

  public signDocumentData(config: {
    thumbprint: string;
    hash: string;
    digestAlgorithm: string;
  }): Promise<string | null> {
    if (!this.instance) {
      return Promise.resolve(null);
    }
    return this.toNativePromise(this.instance.signHash(config));
  }

  public signData(config: {
    thumbprint: string;
    data: string;
    digestAlgorithm: string;
  }): Promise<string | null> {
    return this.toNativePromise(this.instance.signData(config));
  }

  private toNativePromise<T>(promise: LacunaPromise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      promise.success(resolve);
      promise.fail(reject);
    });
  }
}
