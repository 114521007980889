import { Component, inject, signal } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@aprova-digital/design-system';
import { switchMap, tap } from 'rxjs';

import { Types } from '../../../domain/types.domain';

import { AppHandler } from '../../app.handler';
import { InputComponent } from '../../components/input/input.component';
import { RecaptchaComponent } from '../../components/recaptcha/recaptcha.component';
import { IconComponent } from '../../components/icon/icon.component';
import { BoxErrorComponent } from '../../components/box-error/box-error.component';

import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { RecoverPasswordServiceInstruction } from '../../../builder/instructions/services/recover-password.service.instruction';

@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [
    AsyncPipe,
    NgOptimizedImage,
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    RecaptchaComponent,
    IconComponent,
    BoxErrorComponent,
  ],
  providers: [
    new AuthRepositoryInstruction(),
    new RecoverPasswordServiceInstruction(),
  ],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss',
})
export class RecoverPasswordComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);

  private readonly _recoverPasswordService = inject(
    Types.RecoverPasswordService
  );
  private readonly _authRepository = inject(Types.AuthRepository);

  protected readonly data$ = this._route.data.pipe(
    switchMap(async ({ city }) => {
      const config = await this._authRepository.getConfigByCityId(city.index);

      return {
        cityIdentity: city.identidade,
        cityConnection: city.connection,
        hideRecaptcha: config.hideRecaptcha ?? false,
      };
    }),
    tap((data) => {
      this.cityIdentity = data.cityIdentity;
      this.cityConnection = data.cityConnection;
    })
  );

  public loading = signal(false);

  public hideRecaptcha: boolean = false;
  public cityLogo: string | undefined = undefined;
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];

  public form = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    recaptchaReactive: new FormControl(''),
  });

  ngOnInit(): void {
    this._appHandler.setBackButtonHome(false);
    this._appHandler.setLoading(false);
  }

  redirectToLogin(): void {
    this._appHandler.setLoading(true);
    this._router.navigate([`/${this.cityIdentity}`]);
  }

  onSubmit(): void {
    if (
      !this.form.controls.email.valid ||
      !this.form.controls.recaptchaReactive.valid
    ) {
      return;
    }

    this.loading.set(true);

    this._recoverPasswordService
      .handle({
        city: this.cityIdentity,
        connection: this.cityConnection,
        email: this.form.controls.email.value,
        recaptchaReactive: this.form.value.recaptchaReactive,
      })
      .then(() => {
        this.loading.set(false);

        this._appHandler.setError(true, {
          message: 'Enviamos um e-mail de recuperação de senha',
          description: `Caso o e-mail <strong>${this.form.controls.email.value}</strong> tenha uma conta cadastrada, enviaremos um link para recuperar senha. Para isso, verifique sua caixa de e-mail.`,
          options: {
            useHtmlInMessage: true,
          },
        });
      })
      .catch(() => {
        this.loading.set(false);

        this._appHandler.setError(true, {
          message: 'Enviamos um e-mail de recuperação de senha',
          description: `Caso o e-mail <strong>${this.form.controls.email.value}</strong> tenha uma conta cadastrada, enviaremos um link para recuperar senha. Para isso, verifique sua caixa de e-mail.`,
          options: {
            useHtmlInMessage: true,
          },
        });
      });
  }
}
